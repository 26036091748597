<template>
  <v-app>
    <HeaderComponent color="yellow" />

    <v-layout class="mt-16 text-left legal-container">
      <v-container class="my-16" fill-height>
        <v-row justify="center">
          <v-col cols="10" md="8"
            ><h2>Legal disclaimer</h2>
            <p>Effective Date: 21-06-2024</p>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="10" md="8">
            <h3>Welcome to Twisted Twenty</h3>
            <p>
              The information provided by Twisted Twenty (“we,” “us” or “our”)
              on <strong>twistedtwenty.com</strong> (the “Site”) is for general
              informational purposes only. All information on the Site is
              provided in good faith, however, we make no representation or
              warranty of any kind, express or implied, regarding the accuracy,
              adequacy, validity, reliability, availability, or completeness of
              any information on the Site.
            </p>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="10" md="8">
            <h3>External Links Disclaimer</h3>
            <p>
              The Site may contain (or you may be sent through the Site) links
              to other websites or content belonging to or originating from
              third parties or links to websites and features. Such external
              links are not investigated, monitored, or checked for accuracy,
              adequacy, validity, reliability, availability, or completeness by
              us.
            </p>
            <p>
              We do not warrant, endorse, guarantee, or assume responsibility
              for the accuracy or reliability of any information offered by
              third-party websites linked through the site or any website or
              feature linked in any banner or other advertising. We will not be
              a party to or in any way be responsible for monitoring any
              transaction between you and third-party providers of products or
              services.
            </p>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="10" md="8">
            <h3>Errors and Omissions Disclaimer</h3>
            <p>
              While we have made every attempt to ensure that the information
              contained in this site has been obtained from reliable sources,
              Twisted Twenty is not responsible for any errors or omissions or
              for the results obtained from the use of this information. All
              information in this site is provided "as is", with no guarantee of
              completeness, accuracy, timeliness or of the results obtained from
              the use of this information, and without warranty of any kind,
              express or implied, including, but not limited to warranties of
              performance, merchantability, and fitness for a particular
              purpose.
            </p>
            <p>
              In no event will Twisted Twenty, its related partnerships or
              corporations, or the partners, agents, or employees thereof be
              liable to you or anyone else for any decision made or action taken
              in reliance on the information in this Site or for any
              consequential, special or similar damages, even if advised of the
              possibility of such damages.
            </p>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="10" md="8">
            <h3>INTELECTUAL PROPERTY</h3>
            <p>
              ©2024 Twisted Twenty®, Dating and Dragons®, and related design
              and composite marks are trademarks of Twisted Twenty. All rights
              reserved.
            </p>
            <p>
              The Websites and their contents (including any trailers, images,
              sounds, etc.), features, and functionality are owned by the
              Company, its licensors, or other providers of such material and
              are protected by Spain and international copyright, trademark,
              patent, trade secret, and other intellectual property or
              proprietary rights laws.
            </p>

            <p>
              These Terms of Use permit you to use the Services and view the
              Websites and the content set forth therein for your personal,
              non-commercial use only. You must not reproduce, distribute,
              modify, create derivative works of, publicly display, publicly
              perform, republish, download, store, or transmit any of the
              material on our Websites. You must not access or use for any
              commercial purposes any part of the Websites or any services or
              materials available through them. No right, title, or interest in
              or to the Websites or any content on the Websites is transferred
              to you, and all rights not expressly granted are reserved by the
              Company. Any use of the Websites not expressly permitted by these
              Terms of Use is a breach of these Terms of Use and may violate
              copyright, trademark, and other laws.
            </p>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="10" md="8">
            <h3>TRADEMARKS</h3>
            <p>
              The Company’s name, the terms “Twisted Twenty” and “Dating and
              Dragons”, the Company’s logo, and all related names, logos,
              product and service names, designs, and slogans are trademarks of
              the Company or its affiliates or licensors. You must not use such
              marks without the prior written permission of the Company. All
              other names, logos, product and service names, designs, and
              slogans on the Websites are the trademarks of their respective
              owners.
            </p>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="10" md="8">
            <h3>Contact Us</h3>
            <p>
              If you have any questions or concerns about this disclaimer,
              please contact us at:
              <a
                class="pointer underline-hover t20-secondary-color"
                href="mailto:contact@twistedtwenty.com"
                >contact@twistedtwenty.com</a
              >
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>

    <FooterComponent />
  </v-app>
</template>

<script setup lang="ts">
import { defineComponent } from "vue";

import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

defineComponent({
  name: "LegalInfoView",
  components: {
    FooterComponent,
    HeaderComponent,
  },
});
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.legal-container {
  h2,
  h3 {
    color: $t20-primary-color;
  }

  p {
    color: $white;
    text-shadow: $black 0 0 8px;
  }

  background-image: url("../../src/assets/t20_legal_background.webp");
  background-size: cover;
  background-repeat: repeat;
}
</style>
