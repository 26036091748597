<template>
  <v-app class="dating-and-dragons-app">
    <v-btn
      class="subscription-floating-btn"
      rounded="xl"
      @click="openSubscriptionModal"
      elevation="8"
      :size="isSm ? 'small' : 'small'"
    >
      <div v-if="!notificationRead && !isSm" class="notification-text ml-2">
        Stay updated!
      </div>
      <svg-icon
        type="mdi"
        :path="
          !notificationRead ? mdiEmailHeartOutline : mdiEmailOpenHeartOutline
        "
        class="dnd-primary-color pointer clickable-icon"
        :width="isSm ? 28 : 36"
        :height="isSm ? 28 : 36"
        :class="{ 'ml-2': !notificationRead && !isSm }"
      ></svg-icon>

      <div v-if="!notificationRead" class="pending-notification">!</div>
    </v-btn>

    <BackgroundComponent />

    <HeaderComponent />

    <v-container class="dating-and-dragons-thumbnail" fluid></v-container>

    <v-divider class="divider border-opacity-100" :thickness="6" />

    <GameSummaryComponent />

    <v-divider
      class="divider border-opacity-100"
      id="dev-blog-title-separator"
      :thickness="6"
    />

    <DevBlogComponent />

    <v-divider class="divider border-opacity-100" :thickness="6" />

    <PartyComponent />

    <FooterComponent />

    <v-dialog max-width="480" v-model="subscriptionModalOpen">
      <NewsletterModalComponent @close-modal="subscriptionModalOpen = false" />
    </v-dialog>
  </v-app>
</template>

<script setup lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import PartyComponent from "@/components/dating-and-dragons/PartyComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import GameSummaryComponent from "@/components/dating-and-dragons/GameSummaryComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import BackgroundComponent from "@/components/BackgroundComponent.vue";
import DevBlogComponent from "@/components/dating-and-dragons/DevBlogComponent.vue";
import NewsletterModalComponent from "@/components/NewsletterModalComponent.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { mdiEmailHeartOutline } from "@mdi/js";
import { mdiEmailOpenHeartOutline } from "@mdi/js";

defineComponent({
  name: "DnDView",
  components: {
    GameSummaryComponent,
    FooterComponent,
    HeaderComponent,
    PartyComponent,
    BackgroundComponent,
    NewsletterModalComponent,
    SvgIcon,
  },
});

onMounted(() => {
  notificationRead.value =
    localStorage.getItem("subscriptionNotificationRead") === "true";
});

const sm = useDisplay().smAndDown;
const isSm = computed(() => sm.value);

const subscriptionModalOpen = ref<boolean>(false);
const notificationRead = ref<boolean>(false);

const openSubscriptionModal = () => {
  subscriptionModalOpen.value = true;

  localStorage.setItem("subscriptionNotificationRead", "true");
  notificationRead.value = true;
};
</script>

<style scoped lang="scss">
@import "@/styles/styles.scss";

.dating-and-dragons-thumbnail {
  background: url("~@/assets/game_summary_image.webp");
  background-size: cover;

  aspect-ratio: 16 / 9;

  margin-top: $margin-lg + $margin-sm;
}

.divider {
  color: $dnd-primary-color;
}

.subscription-floating-btn {
  position: -webkit-fixed;
  position: fixed;
  top: 98px;
  height: 64px !important;
  right: $margin-md;

  z-index: 1009;

  color: $dnd-primary-color;
}

@media (max-width: 960px) {
  .subscription-floating-btn {
    right: $margin-xs;
    top: 90px;
    height: 48px !important;
  }
}

.pending-notification {
  position: absolute;
  top: -8px;
  right: -8px;

  width: 24px;
  height: 24px;

  border-radius: 100%;

  background-color: $dnd-primary-color;
  color: $white;

  font-size: 1rem;
  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-text {
  font-size: 1rem;
  font-weight: 600;
}
</style>
