<template>
  <div class="modal-container">
    <svg-icon
      type="mdi"
      :path="mdiCloseThick"
      class="close-btn pointer clickable-icon"
      @click="$emit('close-modal')"
    ></svg-icon>

    <form @submit.prevent="submit" v-if="!subscribed && !isError">
      <v-card class="text-center">
        <v-card-title>Newsletter subscription</v-card-title>
        <v-card-text>
          <p class="mb-4">
            Please, introduce your email address to subscribe to our free
            newsletter:
          </p>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            hide-details="auto"
            label="Email"
            placeholder="loudbard@example.com"
            class="mb-4"
          ></v-text-field>

          <v-checkbox v-model="checkbox" :rules="checkboxRules">
            <template v-slot:label>
              <div>
                I have read and agree to the website's
                <v-tooltip location="bottom">
                  <template v-slot:activator="{ props }">
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      v-bind="props"
                      @click.stop
                    >
                      privacy policy
                    </a>
                  </template>
                  Opens in new window
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="
              !emailRules.every((rule) => rule(email) === true) ||
              !checkboxRules.every((rule) => rule(checkbox) === true) ||
              loading
            "
            class="modal-button"
            v-if="!loading"
            text="Subscribe"
            @click="submit()"
          ></v-btn>
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            class="mx-4"
          ></v-progress-circular>
        </v-card-actions>
      </v-card>
    </form>

    <v-card class="text-center" v-if="subscribed">
      <v-card-title>Subscription successful!</v-card-title>
      <v-card-text>
        <p class="thanks-text">
          Thanks for subscribing to our newsletter
          <svg-icon
            type="mdi"
            :path="mdiCheckOutline"
            class="ml-2 ok-icon"
          ></svg-icon>
        </p>
      </v-card-text>
    </v-card>

    <v-card class="text-center" v-if="isError">
      <v-card-title>Critical Failure!</v-card-title>
      <v-card-text class="error-container">
        <svg-icon
          type="mdi"
          width="64"
          height="64"
          :path="mdiAlertCircleOutline"
        ></svg-icon>
        <p>
          We rolled a 1 and something went sideways... Please, try again later.
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { ref, defineComponent } from "vue";
import newsletterSubscriptionService from "@/services/NewsletterSubscriptionService";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCheckOutline, mdiCloseThick, mdiAlertCircleOutline } from "@mdi/js";

defineComponent({
  components: {
    SvgIcon,
  },
  emits: ["close-modal"],
});

const email = ref("");
const subscribed = ref(false);
const isError = ref(false);
const loading = ref(false);
const checkbox = ref(false);

const emailRules = [
  (value?: string) => !!value || "Required.",
  (value?: string) =>
    /.+@.+\..+/.test(value as string) || "E-mail must be valid.",
];

const checkboxRules = [
  (value?: boolean) => !!value || "You must accept the privacy policy.",
];

const submit = async () => {
  if (
    emailRules.every((rule) => rule(email.value) === true) &&
    checkboxRules.every((rule) => rule(checkbox.value) === true)
  ) {
    loading.value = true;
    try {
      await newsletterSubscriptionService.postNewsletterSubscription(
        email.value,
      );
      subscribed.value = true;
    } catch (error) {
      isError.value = true;
    } finally {
      loading.value = false;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/index.scss";

// TODO: why do this (modal) component does not inherit the font-family from the global styles?
*,
*::before,
*::after {
  font-family: "Averia Sans Libre", sans-serif;
  font-style: normal;
}

.v-card-title {
  font-size: 1.25rem !important;
  font-weight: 600 !important;
}

.modal-container {
  background-image: url("../assets/monster_background.webp");

  .v-card-text {
    font-size: 1rem !important;
  }

  .close-btn {
    z-index: 99;
    position: absolute !important;
    right: 16px;
    top: 16px;
  }

  .v-card {
    padding: $margin-xs;
  }

  .modal-button {
    box-shadow: 0 0 2px 2px $dnd-quinary-color;

    &:hover {
      box-shadow: 0 0 6px 2px $dnd-tertiary-color;
    }
  }

  .thanks-text {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ok-icon {
    color: $success-color;
  }

  .error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $margin-xs;

    svg {
      color: $danger-color;
    }
  }
}
</style>
