<template>
  <v-app-bar
    app
    :elevation="8"
    class="navigation-bar"
    :color="'black'"
    :height="80"
  >
    <div class="d-flex justify-space-between h-100 w-100">
      <div v-if="!mobile" class="ml-4 w-25"></div>
      <img
        v-if="!mobile"
        class="pointer h-100 w-auto"
        :src="getLogo(`t20_horizontal_logo_${color}`)"
        alt="twisted twenty logo"
        @click="goHome"
      />
      <img
        v-if="mobile"
        class="pointer h-100 w-auto"
        :src="getLogo(`t20_responsive_logo_${color}`)"
        alt="twisted twenty logo"
        @click="goHome"
      />
      <div class="mr-4 w-25 d-flex justify-end">
        <SocialMediaIconsComponent />
      </div>
    </div>
  </v-app-bar>
</template>

<script setup lang="ts">
import { useDisplay } from "vuetify";
import { useRouter } from "vue-router";
import { defineComponent, defineProps } from "vue";
import SocialMediaIconsComponent from "@/components/SocialMediaIconsComponent.vue";

const { mobile } = useDisplay();
const router = useRouter();

defineComponent({
  name: "HeaderComponent",
  components: {
    SocialMediaIconsComponent,
  },
});

defineProps({
  // Colors available: 'white' and 'yellow' for now
  color: {
    type: String,
    default: "white",
  },
});

const goHome = () => {
  window.scrollTo(0, 0);
  router.push({ name: "post-3" });
};

const getLogo = (logoName: string) => {
  return require("@/assets/logos/" + logoName + ".webp");
};
</script>

<style scoped lang="scss">
@import "@/styles/index.scss";

.navigation-bar {
  border-bottom: 2px solid $white;
}

svg:hover {
  color: $t20-secondary-color;
  cursor: pointer;
}
</style>
