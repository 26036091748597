<template>
  <v-container fluid class="py-16 party-container">
    <v-row align="center" justify="space-around">
      <v-col cols="12" md="6">
        <h2 id="the-party">The Party</h2>
      </v-col>
    </v-row>

    <v-row align="center" justify="space-around">
      <v-col cols="12" sm="10">
        <v-row align="stretch" justify="space-around">
          <v-col cols="12" md="10" lg="4">
            <MonsterCard :monster="lyd"></MonsterCard>
          </v-col>
          <v-col cols="12" md="10" lg="4">
            <MonsterCard :monster="vic"></MonsterCard>
          </v-col>
          <v-col cols="12" md="10" lg="4">
            <MonsterCard :monster="javi"></MonsterCard>
          </v-col> </v-row
      ></v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import MonsterCard from "@/components/dating-and-dragons/MonsterCard.vue";
import { Monster } from "@/models/monster";
import javiImage from "@/assets/javi.webp";
import lydImage from "@/assets/lyd.webp";
import vicImage from "@/assets/vic.webp";

const lyd: Monster = {
  name: 'Lyd "Cookie" Platas',
  type: "Illusionist, chaotic gremlin",
  img: lydImage,
  strength: 13,
  dexterity: 12,
  constitution: 18,
  intelligence: 17,
  wisdom: 16,
  charisma: 16,
  specialAbilities: [
    {
      name: "Mirage Sorcerer",
      description:
        "Whenever a party member is focused on the image of any object, creature or location, Lyd can visualize it and summon it from the Conceptual Plane using her artist tools. The concept then becomes real and beautiful to any observer. ",
    },
    {
      name: "Chaoz Aspect",
      description:
        "Each day at dawn, Lyd must roll a d20. On a 10 or lower, she transforms into a frenzied goblin and must spend at least 8 hours doing mischiefs and pranks; then she gains advantage on all skill checks for the rest of the day. ",
    },
  ],
  actions: [
    {
      name: "Godmemes",
      description:
        "Lyd carries at all times a bag of holding memes. Twice per long rest, she can draw a meme and use it to inspire an ally, restoring 1d6 (+3) hit points. Additionally, she can perform a 1-hour ritual each day to create never seen before memes. ",
    },
  ],
};

const vic: Monster = {
  name: 'Vic "Kirlyan" Bello',
  type: "Scroll Keeper, chill neutral",
  img: vicImage,
  strength: 9,
  dexterity: 12,
  constitution: 14,
  intelligence: 20,
  wisdom: 16,
  charisma: 15,
  specialAbilities: [
    {
      name: "Multiclass",
      description:
        "Not only a wise mage, Vic is also a 2nd level Unity Cleric, which grants him coding skills. With prayer and meditation, he can add any design scroll found during his adventures into his codebook.",
    },
    {
      name: "Multiverse Traveler",
      description:
        "As a bonus action, Vic can open a portal to a different plane using any piece of fiction as a focus. He becomes inspired and gains advantage on creativity checks.",
    },
  ],
  actions: [
    {
      name: "Find Familiar",
      description:
        "Twice per long rest, Vic can summon any or both of his cat familiars, Mystra and Nyx. While they are summoned Vic can add a 1d4 bonus to any wisdom saving throw against stress or anxiety.",
    },
  ],
};

const javi: Monster = {
  name: 'Javi "Tattoos" S. Fdez',
  type: "Codex Architect, tired good",
  img: javiImage,
  strength: 15,
  dexterity: 18,
  constitution: 8,
  intelligence: 15,
  wisdom: 18,
  charisma: 18,
  specialAbilities: [
    {
      name: "Binary mind",
      description:
        "Javi's brain is built in binary, they can talk with any machine-like constructs and program them. Whenever they are talking with a machine, they receive a +4 bonus on all Charm based checks.",
    },
    {
      name: "Mimic Ancestry",
      description:
        "A drop of mimic blood runs through Javi’s veins, granting them incredible adaptiveness. When levelling up, Javi can train any new skill and become proficient with it.",
    },
  ],
  actions: [
    {
      name: "Leader of the Cult of the Deadline",
      description:
        "Javi can inspire any member of the party up to 2 times per long rest and grant them a 1d6 bonus on one motivation check, teamwork roll or deadline saving throw of their choosing.",
    },
  ],
};
</script>

<style lang="scss" scoped>
.party-container {
  background: url("../../assets/team_background.webp");
  background-size: 2000px 1000px;
  background-repeat: repeat;
}
</style>
