<template>
  <v-layout>
    <div
      ref="historyButtonContainer"
      class="history-button-container hover-right ml-0 sm-6 ml-md-10 ml-lg-12 ml-xl-16"
      v-if="!drawer"
      @click.stop="openHistoryDrawer"
    >
      <div class="d-flex align-center justify-center w-auto">
        <img
          class="w-100 h-100 pa-4"
          :src="kobold"
          alt="dating and dragons pet"
        />
        <p>See more posts</p>
      </div>
    </div>

    <v-container fluid class="dev-blog-container">
      <v-row justify="center" class="mt-16 mb-8">
        <v-col cols="12" class="d-flex flex-nowrap justify-center align-center">
          <h2 class="dev-blog-title">Dev blog</h2>
          <IconComponent color="#b91212" icon="quill" class="ml-2" :size="48" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="10" lg="6" class="mb-4">
          <router-view />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          cols="12"
          md="12"
          lg="8"
          xl="6"
          class="dev-blog-footer white-color text-shadow"
        >
          <p>
            Do you want to stay updated?
            <v-btn
              :elevation="8"
              class="ml-2 subscription-btn"
              @click="subscriptionModalOpen = true"
              >Subscribe to our newsletter!</v-btn
            >
          </p>
          <p class="mb-0">
            We promise not to spam you and we may also promise some love
            letters, maybe?
          </p>
          <v-dialog max-width="480" v-model="subscriptionModalOpen">
            <NewsletterModalComponent
              @close-modal="subscriptionModalOpen = false"
            />
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>

    <v-navigation-drawer
      :elevation="4"
      absolute
      app
      v-model="drawer"
      :width="xlAndUpValue ? 420 : 280"
      class="history-drawer pa-8 super-padding"
    >
      <template v-slot:prepend>
        <svg-icon
          type="mdi"
          :path="mdiClose"
          :size="32"
          @click.stop="drawer = false"
          class="pointer close-drawer-button"
        ></svg-icon>
        <div class="my-4">
          <h3>History</h3>
        </div>
        <div class="kobold-reading-container my-4">
          <img
            class="h-100 w-auto"
            :src="koboldReading"
            alt="dating and dragons pet"
          />
        </div>
      </template>
      <div class="dev-blog-nav">
        <router-link
          to="/dating-and-dragons/dev-blog/1"
          active-class="navigation-active"
        >
          LVL 1 - The adventure begins: meet the party at the tavern!
        </router-link>

        <router-link
          to="/dating-and-dragons/dev-blog/2"
          active-class="navigation-active"
        >
          LVL 2 - The First Quest: Discovering the Village!
        </router-link>

        <router-link
          to="/dating-and-dragons/dev-blog/3"
          active-class="navigation-active"
        >
          LVL 3 - Choose Your Companion!
        </router-link>
        <div class="d-flex flex-nowrap justify-center align-center mt-8 ga-2">
          <p class="dnd-dark-primary-color more-soon mb-0">More soon!</p>
          <IconComponent icon="like" color="#b91212" :size="24" />
        </div>
      </div>
    </v-navigation-drawer>
  </v-layout>
</template>

<script setup lang="ts">
import { mdiClose } from "@mdi/js";
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import IconComponent from "@/components/IconComponent.vue";
import NewsletterModalComponent from "@/components/NewsletterModalComponent.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import kobold from "@/assets/kobold_chest.png";
import koboldReading from "@/assets/kobold_reading.png";

const xlAndUp = useDisplay().xlAndUp;

const xlAndUpValue = computed(() => xlAndUp.value);

const drawer = ref<boolean | null>(null);
const subscriptionModalOpen = ref<boolean>(false);

const historyButtonContainer: Ref<HTMLElement | null> = ref(null);

defineComponent({
  name: "DevBlogComponent",
  components: {
    NewsletterModalComponent,
    IconComponent,
    SvgIcon,
  },
});

onMounted(() => {
  window.addEventListener("scroll", handleHistoryScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleHistoryScroll);
});

const handleHistoryScroll = () => {
  const historyButtonContainerValue = historyButtonContainer.value;
  if (!historyButtonContainerValue) return;

  const devBlogContainer = historyButtonContainerValue?.parentElement;

  const devBlogContainerRect = devBlogContainer?.getBoundingClientRect();
  const devBlogContainerTop = devBlogContainerRect?.top || 0;
  const devBlogContainerHeight = devBlogContainerRect?.height || 0;
  const devBlogContainerBottom =
    devBlogContainerTop +
    devBlogContainerHeight -
    historyButtonContainerValue?.getBoundingClientRect().height * 1.5; // subtract four times the height of the button

  if (devBlogContainerTop <= 40 && devBlogContainerBottom >= 0) {
    (historyButtonContainerValue as HTMLElement).style.position = "fixed";
    (historyButtonContainerValue as HTMLElement).style.top = "80px"; //
  } else {
    (historyButtonContainerValue as HTMLElement).style.position = "absolute";
    (historyButtonContainerValue as HTMLElement).style.top = "20px";
  }
};

const openHistoryDrawer = () => {
  const devBlogSeparator = document.getElementById(
    "dev-blog-title-separator",
  )?.offsetTop;

  if (devBlogSeparator) {
    window.scrollTo({
      top: devBlogSeparator - 80,
      behavior: "smooth",
    });
  }
  drawer.value = true;
};
</script>

<style scoped lang="scss">
@import "@/styles/index.scss";

.dev-blog-container {
  background-image: url("../../assets/dev_blog_background.webp");
  background-size: 2000px 1000px;
  background-repeat: repeat;
}

.dev-blog-footer {
  margin-bottom: $margin-lg;
}

.history-drawer {
  background-image: url("../../assets/monster_background.webp");
  border-right: 4px solid $dnd-primary-color;

  .close-drawer-button {
    display: block;
    margin-left: auto;
  }

  /* remove the scroll bar */
  ::v-deep .v-navigation-drawer__content {
    overflow-y: auto;
    overflow-x: hidden !important;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .dev-blog-nav {
    display: flex;
    flex-direction: column;

    text-align: start;
    a {
      padding-right: 12px;
      padding-top: 8px;
      padding-bottom: 8px;
      text-decoration: none;
      color: $dnd-dark-secondary;
      border-right: 3px solid $dnd-dark-secondary;

      &:hover {
        color: $dnd-light-secondary;
        border-right: 4px solid $dnd-light-secondary;
      }
    }

    .router-link-active {
      color: $dnd-light-primary;
      border-right: 4px solid $dnd-light-primary;
    }
  }

  .kobold-reading-container {
    height: 144px;
  }
}

.navigation-active {
  color: $dnd-primary-color;
}

.blog-title {
  margin-bottom: 32px;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
}

.history-button-container {
  position: absolute;
  left: -304px;
  z-index: 999;

  padding-left: 88px;

  img {
    max-width: 200px;
    margin-left: 16px;
  }

  p {
    font-size: 1.5em;
    font-weight: bold;
    writing-mode: tb;
    margin: auto;
    color: $dnd-dark-primary;
  }

  background-color: $white;
  box-shadow: 0px 0px 16px 4px $box-shadow-color;
  border-radius: 20px;

  cursor: pointer;
}

@media (max-width: 420px) {
  .history-button-container {
    left: -296px;
  }
}

@media (min-width: 1600px) {
  .history-button-container {
    left: -282px;
  }
}

@media (min-width: 2200px) {
  .history-button-container {
    left: -278px;
  }
}
.subscription-btn {
  color: $white;
  background-color: $dnd-primary-color;
  border-radius: 4px;
}

.hover-right {
  transition: all 0.3s ease;

  &:hover {
    transform: translateX(196px);
  }
}

.super-padding {
  padding-left: 64px !important;
}

@media (max-width: 420px) {
  .dev-blog-title {
    font-size: 2em !important;
  }
}

@media (max-width: 960px) {
  .super-padding {
    padding-left: 32px !important;
  }
}

@media (min-width: 2100px) {
  .super-padding {
    padding-left: 128px !important;
  }
}
@media (min-width: 1600px) {
  .super-padding {
    padding-left: 88px !important;
  }
}
</style>
