<template>
  <v-container class="blog-entry-container py-16">
    <v-row justify="center">
      <v-col xs="12" cols="10" class="text-left">
        <div class="avatar mb-4" v-if="imgfileName">
          <v-avatar size="65px">
            <v-img
              alt="avatar mb-4"
              :src="require(`@/assets/${imgfileName}`)"
            ></v-img>
          </v-avatar>
          <div class="ml-4">
            <p class="mb-0 mt-4">
              <b>{{ author }}</b>
            </p>
            <p class="dnd-tagline">{{ authorTitle }}</p>
          </div>
        </div>
        <h3>{{ postTitle }}</h3>
        <p class="mb-0">
          <time :datetime="new Date(date).toISOString()" class="dnd-tagline">
            {{ date }}
          </time>
        </p>
      </v-col>
    </v-row>

    <v-row justify="center" class="text-left">
      <v-col cols="10">
        <slot name="content"></slot>
      </v-col>
    </v-row>

    <v-row justify="center" class="text-align-end">
      <v-col xs="12" cols="10" class="text-right">
        <slot name="signature"></slot>
      </v-col>
    </v-row>

    <v-row justify="center" class="text-left" v-if="showNewFeatures">
      <v-col cols="10">
        <h4>New features:</h4>
        <slot name="new-features"></slot>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="10">
        <v-divider
          class="divider border-opacity-100"
          :thickness="4"
        ></v-divider>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col xs="12" cols="10" class="text-left">
        Got any feedback or suggestions? Or maybe you just want to show some
        appreciation? Please do so on our
        <a class="pointer underline-hover" href="https://discord.gg/kEeEV3HZaW"
          >discord server</a
        >!
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="10">
        <div class="share-container" v-if="!linkCopied">
          <p class="pointer" @click="sharedButtonClickHandler">Share:</p>
          <svg-icon
            type="mdi"
            :path="mdiShareVariantOutline"
            class="dnd-primary-color pointer ml-2 clickable-icon"
            @click="sharedButtonClickHandler"
          ></svg-icon>
        </div>

        <div class="share-container" v-if="linkCopied">
          <Transition name="slide">
            <p v-if="linkCopied">Link copied to the clipboard!</p>
          </Transition>
          <Transition name="slide">
            <svg-icon
              v-if="linkCopied"
              type="mdi"
              :path="mdiClipboardCheckMultipleOutline"
              class="ml-2"
            ></svg-icon>
          </Transition>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { defineComponent, defineProps, ref, Transition } from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiShareVariantOutline,
  mdiClipboardCheckMultipleOutline,
} from "@mdi/js";

defineComponent({
  name: "BlogEntryComponent",
  components: {
    SvgIcon,
    Transition,
  },
});

defineProps({
  postTitle: {
    type: String,
    required: true,
  },
  date: {
    type: String,
    required: true,
  },
  imgfileName: {
    type: String,
    required: true,
  },
  author: {
    type: String,
    required: true,
  },
  authorTitle: {
    type: String,
    required: true,
  },
  showNewFeatures: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const linkCopied = ref(false);

const sharedButtonClickHandler = () => {
  linkCopied.value = true;

  navigator.clipboard.writeText(window.location.href);

  setTimeout(() => {
    linkCopied.value = false;
  }, 6000);
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/styles.scss";

.post-date {
  color: $grey;
}

.avatar {
  display: flex;
  align-items: center;
}

.blog-entry-container {
  background: url("../../assets/monster_background.webp");
  background-size: cover;
  box-shadow: 0px 0px 8px 4px $box-shadow-color;
  border-top: 4px solid $dnd-primary-color;
  border-bottom: 4px solid $dnd-primary-color;
  max-height: 1080px;
  overflow-y: auto;
  overflow-x: hidden !important;

  scrollbar-width: thin;
  scrollbar-color: $dnd-primary-color $white;
}

@media (max-width: 960px) {
  .blog-entry-container {
    max-height: unset;
  }
}

.share-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  p {
    margin: 0;
  }
}

.slide-enter-active {
  transition: all 0.3s ease-out;
}

.slide-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
