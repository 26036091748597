<template>
  <div class="t20-background"></div>
</template>

<style lang="scss" scoped>
@media (max-width: 960px) {
  .t20-background {
    display: none;
  }
}

.t20-background {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 999;

  pointer-events: none;

  background-image: url("../../src/assets/sides_background.webp");
  background-repeat: repeat-y;
  background-size: contain;
}
</style>
