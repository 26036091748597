<template>
  <router-view />

  <ExternalUrlModalComponent />
</template>

<script setup>
import ExternalUrlModalComponent from "@/components/ExternalUrlModalComponent.vue";
import { defineComponent } from "vue";

defineComponent({
  name: "App",
  components: {
    ExternalUrlModalComponent,
  },
});

document.title = "Twisted Twenty Studios";
</script>

<style lang="scss">
@import "@/styles/variables.scss";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
</style>
