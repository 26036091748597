import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import DnDView from "../views/DnDView.vue";
import Post_1 from "../components/dating-and-dragons/blog-entries/Post_1-14-07-2024.vue";
import Post_2 from "../components/dating-and-dragons/blog-entries/Post_2-28-07-2024.vue";
import Post_3 from "../components/dating-and-dragons/blog-entries/Post_3-14-10-2024.vue";
import LegalInfoView from "@/views/LegalInfoView.vue";
import PrivacyPolicyInfoView from "@/views/PrivacyPolicyInfoView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicyInfoView,
  },
  {
    path: "/legal",
    name: "legal",
    component: LegalInfoView,
  },
  {
    path: "/dating-and-dragons",
    name: "dating-and-dragons",
    component: DnDView,
    children: [
      {
        path: "/:pathMatch(.*)",
        redirect: "/dating-and-dragons/dev-blog/3",
      },
      {
        path: "dev-blog/1",
        name: "post-1",
        component: Post_1,
      },
      {
        path: "dev-blog/2",
        name: "post-2",
        component: Post_2,
      },
      {
        path: "dev-blog/3",
        name: "post-3",
        component: Post_3,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
