<template>
  <v-dialog v-model="urlDialog" max-width="480">
    <v-card class="external-modal-dialog">
      <v-card-title>
        <span class="ml-2">You are going on an adventure!</span>
      </v-card-title>
      <v-divider
        :thickness="2"
        class="border-opacity-100"
        color="#ffe400"
      ></v-divider>
      <v-card-text class="padding-md">
        <p class="margin-xs-bottom">
          You are about to travel to the distant lands of an external realm (or
          how others call, website) called:
        </p>

        <v-divider
          class="divider border-opacity-100"
          :thickness="4"
        ></v-divider>
        <p class="margin-xs-y">{{ externalUrlService.url }}</p>
        <v-divider
          class="divider border-opacity-100 margin-xs-bottom"
          :thickness="4"
        ></v-divider>

        <p class="ma-0">Are you ready to proceed?</p>
      </v-card-text>

      <v-card-actions class="pr-8 pb-4">
        <v-spacer></v-spacer>
        <v-btn @click="goToUrl()" class="go-button">
          "Yes, take me there!"
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed } from "vue";
import externalUrlService from "@/services/ExternalUrlService";

const urlDialog = computed({
  get: () => externalUrlService.modalState,
  set: (value) => {
    externalUrlService.modalState = value;
  },
});

const goToUrl = () => {
  externalUrlService.goToUrl();
};
</script>

<style scoped lang="scss">
@import "@/styles/index.scss";
// TODO: why do this (modal) component does not inherit the font-family from the global styles?
*,
*::before,
*::after {
  font-family: "Averia Sans Libre", sans-serif;
  font-style: normal;
}

.go-button {
  max-width: 360px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  box-shadow: 0 0 2px 2px $t20-secondary-color;

  &:hover {
    box-shadow: 0 0 6px 2px $t20-secondary-color;
  }
}

.external-modal-dialog {
  background-color: $black;

  color: $white;

  border-color: $t20-secondary-color;
  border-width: 2px;
  border-radius: 12px !important;
}
</style>
