<template>
  <v-app>
    <HeaderComponent color="yellow" />

    <v-layout class="mt-16 text-left legal-container">
      <v-container class="my-16" fill-height>
        <v-row justify="center">
          <v-col cols="10" md="8"
            ><h2>Privacy Policy</h2>
            <p>Effective Date: 21-07-2024</p>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="10" md="8">
            <h3>1. Introduction</h3>
            <p>
              Welcome to Twisted Twenty. Your privacy is important to us. This
              Privacy Policy explains how we collect, use, and protect your
              email address when you visit our website and use our services. By
              using our website, you agree to the collection and use of
              information in accordance with this policy.
            </p>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="10" md="8">
            <h3>2. Information We Collect</h3>
            <p>
              <b>Email Address:</b> When you sign up for our services or
              newsletters, we collect your email address.
            </p>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="10" md="8">
            <h3>3. How We Use Your Information</h3>
            <p>We use your email address for the following purposes:</p>
            <ul>
              <li>To provide and maintain our services</li>
              <li>To notify you about changes to our services</li>
              <li>
                To send you updates, newsletters, and promotional materials, if
                you have opted in to receive them
              </li>
              <li>To respond to your inquiries and provide customer support</li>
            </ul>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="10" md="8">
            <h3>4. Sharing Your Information</h3>
            <p>
              We do not sell or rent your email address to third parties. We may
              share your email address with:
            </p>
            <ul>
              <li>
                <b>Service Providers:</b> We may employ third-party companies
                and individuals to facilitate our services, provide services on
                our behalf, or perform website-related services. We will make
                sure to the best of our capabilities that these third parties
                have access to your data only to perform these tasks on our
                behalf and are obligated not to disclose or use it for any other
                purpose.
              </li>
              <li>
                <b>Legal Requirements:</b> We may disclose your email address if
                required to do so by law or in response to valid requests by
                public authorities.
              </li>
            </ul>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="10" md="8">
            <h3>5. Data Security</h3>
            <p>
              We strive to use commercially acceptable means to protect your
              email address. However, no method of transmission over the
              Internet or method of electronic storage is 100% secure. While we
              aim to use reasonable means to protect your email address, we
              cannot guarantee its absolute security.
            </p>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="10" md="8">
            <h3>6. Your Privacy Rights</h3>
            <p>
              You have the right to request the removal of your personal data
              from our system. To exercise this right, please send an email from
              the address you registered with to
              <a
                class="pointer underline-hover t20-secondary-color"
                href="mailto:contact@twistedtwenty.com"
                >contact@twistedtwenty.com</a
              >,
              <strong
                >specifying that you wish your personal data to be removed from
                our database</strong
              >. We will process your request promptly and confirm the deletion
              of your data.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>

    <FooterComponent />
  </v-app>
</template>

<script setup lang="ts">
import { defineComponent } from "vue";

import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

defineComponent({
  name: "PrivacyPolicyInfoView",
  components: {
    FooterComponent,
    HeaderComponent,
  },
});
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";
h2,
h3 {
  color: $t20-primary-color;
}

p,
ul,
li {
  color: $white;
  text-shadow: $black 0 0 8px;
}

.legal-container {
  background-image: url("../../src/assets/t20_legal_background.webp");
  background-size: cover;
  background-repeat: repeat;
}
</style>
