<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <article>
    <BlogEntryComponent
      postTitle="LVL 2 - The First Quest: Discovering the Village!"
      date="July 28, 2024"
      author="Victor 'Kirlyan' Bello"
      authorTitle="Scroll Keeper"
      imgfileName="vic_avatar.webp"
    >
      <template v-slot:content>
        <p class="mb-8">
          Greetings, adventurers! Last week we said only a few words about our
          game. Now the time has come: let’s talk about Dating and Dragons: A
          Love Quest!
        </p>

        <section class="mb-8 mt-4">
          <h4 class="mb-4">
            <b
              >The core game loop: date cool & sexy characters in a fantasy
              world.
            </b>
          </h4>

          <p>
            Your quest in Dating and Dragons is simple: choose your favorite
            from a pool of six characters specially designed to be sexy af and
            romance them. Doing it won’t be that simple though. In a world full
            of magic, bandits, monsters, and disguised chests with ravenous
            hunger, impressing them might take more than just a charming smile.
          </p>

          <p>
            Who will you choose? We could spend hours talking about our
            characters, their traits, quirks, and secrets. And yes, we mean
            secret endings, wink, wink! But why listen to us when they can
            introduce themselves? Here’s a glimpse into what their dating app
            profiles might look like:
          </p>

          <hr />
          <p class="mt-4 mx-2">
            “Find yourself before seeking me, adventurer. Stargazing 😊
            Down-to-earth is a must NOT. Moonlit waltzes with lemon cookies a
            plus.” - <i>Lily, the Druid.</i>
          </p>
          <hr />

          <p class="mt-4 mx-2">
            “Just bored. Must have pic. No, you can’t fix me. Yes, you can pay
            the bill. Let’s skip the chit-chat. Nobody reads these anyway.” -
            <i>Nox, the Rogue.</i>
          </p>
          <hr />

          <p class="mt-4 mx-2">
            “I have several lists of funny jokes to tell on first dates, so at
            least you’ll laugh! Tea enthusiast, quick wit, early bird.
            #OrganizedChaos.” - <i>Ravi, the Wizard.</i>
          </p>
          <hr />

          <p class="mt-4 mx-2">
            “Father of one, business owner, fitness passionate. Happiness is
            giving and receiving love. Let’s forge a future together.” -
            <i>Obi, the Blacksmith.</i>
          </p>
          <hr />

          <p class="mt-4 mx-2">
            “I’ve seen a hydra from the insides, can handle a nude or two but
            ASK CONSENT OR LEARN WHAT THE WORD RAGE MEANS!” -
            <i>Kára, the Monster Hunter.</i>
          </p>
          <hr />

          <p class="mt-4 mx-2">
            “Daily pipa practice = impressive finger dexterity 😉 Ready to be
            serenaded, darling? And yeah, don't worry, my mouth can do more than
            just sing - <i>Yào, the Bard.</i>
          </p>
          <hr />
        </section>

        <section class="mb-8 mt-4">
          <h4 class="mb-4">
            <b>The style: Cartoony, unpredictable and humorous.</b>
          </h4>

          <p>
            Dating and Dragons is a love letter to classic tabletop games. We’ve
            added references, Easter eggs, and nods to the games you love into
            the dialogues, backgrounds, and UI. Our players should find many
            relatable moments during their playthroughs. We’ve chosen a cartoony
            style for the illustrations; and the dialogues are light-hearted,
            full of jokes, and bursting with memes. We want you to feel immersed
            in the story and create bonds with the characters, but most of all,
            have a good time of laughter and chill.
          </p>

          <p>
            As in any good RPG game, we wanted to capture the thrill of the
            unexpected in Dating and Dragons. You might enjoy a nice cup of tea
            with Lily in one scene, only to be drawn into a tavern brawl with
            Nox in the next one. Or maybe you’ll uncover a goblin plot to kidnap
            and replace the entire government. The point is, you’ll never know
            what’s around the corner!
          </p>
        </section>

        <section class="mb-8 mt-4">
          <h4 class="mb-4">
            <b
              >The mechanics: choose your words, roll some dice, put your Brains
              and Muscles to work!
            </b>
          </h4>

          <p>
            As a graphic adventure, dialogue is a huge part of our game. Each
            scene will present a unique situation for you and your potential
            soulmate to solve. Inevitably, you will face a challenge or
            decision, and will choose how to face it.
          </p>

          <p>
            Pick your response, then roll the dice! The system couldn’t be
            simpler: roll higher than the difficulty check and you succeed, roll
            lower and that’s a failure. Succeeding or failing will have
            consequences. Remember, you’re here to impress your date! Also, some
            scenes will trigger more complex plots that will develop through
            several scenes, so the consequences of your decisions might come
            later to haunt you…
          </p>

          <p>
            Don’t worry, success isn’t just left in the hands of fate. At the
            beginning, you pick a character out of 4 options, each with unique
            stats. The warrior has high Muscles, so she is more likely to
            succeed at brute force tasks. The wizard, on the other hand, should
            try to face problems using his galaxy Brains. Pick the character
            that suits your playstyle (or just the hottest!) and use their
            strengths to your advantage.
          </p>
        </section>

        <section class="mb-8 mt-4">
          <h4 class="mb-4">
            <b>The goal: quick fast paced runs, each session with a twist!</b>
          </h4>

          <p>
            An average playthrough of Dating and Dragons shouldn’t take longer
            than an hour. During that time, you’ll play a dozen scenes. And with
            hundreds more to discover, replaying will be a core mechanic in our
            game. New characters, plots, magical items, and secret endings will
            be waiting for you after the credits roll.
          </p>

          <p>
            Even though Dating and Dragons is still in early development, we’re
            already very proud of how it’s shaping up. If you, like us, have
            always wanted to romance charming characters in a world filled with
            magic and adventure, then this game is definitely for you!
          </p>

          <p>
            Thank you so much for joining us on this adventure! Stay tuned for
            the next update and until then, may your dice always roll in your
            favor!
          </p>
        </section>
      </template>

      <template v-slot:signature>
        <p><b>Twisted Twenty Studio</b></p>
      </template>

      <template v-slot:new-features>
        <p>None (for now!).</p>
      </template>
    </BlogEntryComponent>
  </article>
</template>

<script setup>
import { defineComponent } from "vue";
import BlogEntryComponent from "@/components/dating-and-dragons/BlogEntryComponent.vue";

defineComponent({
  name: "BlogEntryComponent",
  components: {
    BlogEntryComponent,
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/styles.scss";
</style>
