<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <article>
    <BlogEntryComponent
      postTitle="LVL 1 - The adventure begins: meet the party at the tavern!"
      date="July 21, 2024"
      author="Victor 'Kirlyan' Bello"
      authorTitle="Scroll Keeper"
      imgfileName="vic_avatar.webp"
    >
      <template v-slot:content>
        <p>Hello world!</p>

        <p>
          We’re so thrilled to be publishing our first-ever development update!
          Woohoo! First things first, let’s take a moment to thank you for being
          here. If you’re reading this, you’re one of the earliest people to
          take interest in our adventure, and we couldn’t be more grateful. We
          truly hope you like what you see, and if you decide to stick around
          with us, we'll do our best to make it worth your while. Thank you for
          your support, it means the world to us!
        </p>

        <p>Now, let's get to introductions!</p>

        <p>
          What’s Twisted Twenty? We are a newly formed indie game development
          studio based in Spain, created by three friends with big dreams and
          heads full of ideas. Dating and Dragons will be our inaugural project,
          and we couldn’t be more excited about it!
        </p>

        <p>
          Before jumping into a full presentation of the game, we wanted to
          introduce ourselves and share how this new adventure began.
        </p>

        <p>Introducing, our party!</p>

        <ul>
          <li>
            Javi “Tattoos” (they) have taken the role of our project manager and
            lead programmer. They are the main person responsible for keeping us
            on track and completing tasks on time! Kudos to Tattoos!
          </li>
          <li>
            Lyd “Cookie” (she) is our lead artist. If you’re here because you
            saw some awesome character illustration, she’s the one to praise!
            Lyd’s currently pouring all her talent into bringing our characters
            and backgrounds to life. And what an incredible job she’s done
            already!
          </li>
          <li>
            Vic “Kirlyan” (he) is the lead writer as well as gameplay
            programmer, working hard to polish all the bad jokes our characters
            will say. Whether it’s bugs in the code or typos in the texts, he’s
            committed to catch ‘em all!
          </li>
        </ul>

        <p>
          We’ve been toying around with Dating and Dragons concept and the dream
          of creating our own studio for more than a year. December 2023, the
          question finally arose: Should we do this? We looked at each other for
          a second and the answer was clear: we are SO doing this!
        </p>

        <p>
          Fast forward a few months, and here we are! It’s official, we’re
          developing our first game! Characters are being designed, code is
          being coded, contacts are being contacted and ideas are being ideated.
          We can't wait to show you what we've been working on these past
          months!
        </p>
        <p>
          We’re having a blast working together and hopefully this will be
          reflected in our game. We’re putting our hearts into it, and we think
          it’s already becoming something beautiful. In the next update we’ll be
          showing you what the first concept of the game looks like. For now,
          here’s a brief sneak peak of what you can expect:
        </p>
        <p>
          Dating and Dragons is a narrative adventure with a fun, quirky and
          unapologetic tone. You’ll play as a former adventurer that now is set
          in the most exciting adventure life can offer: finding true love. Or
          maybe you just want to hook up with the sexy bard, the menacing rogue,
          or the cozy druid. You will be able to do so, and we won’t judge
          (much).
        </p>
        <p>
          Our characters are designed to embody classic tabletop game tropes,
          but always with a twist, as our studio name suggests. Expect the
          unexpected! You’ll make choices, roll dice, hopefully laugh a lot,
          possibly sob a few times and most likely facepalm more than once.
        </p>
        <p>
          We plan to update our progress regularly, so keep an eye out on our
          social media, hit that subscribe button below and don’t hesitate to
          ask us anything. We hope to build a friendly, welcoming and inclusive
          community around our game. Your suggestions and support will be
          crucial for us, so we’ll make sure to listen to any feedback you have!
        </p>
        <p>
          To wrap up this first update, we want to thank you once again for
          being here. By accompanying us on our journey and watching our dream
          come true, you are becoming a precious part of our community. From the
          bottom of our hearts, thank you.
        </p>
        <p>
          In the next update, we’ll talk a bit more about Dating and Dragons and
          the core gameplay idea. We’ll also start introducing our characters,
          so you can look forward to that! Until then, may your dice always roll
          in your favour!
        </p>
      </template>

      <template v-slot:signature>
        <p><b>Vic, Lyd & Javi</b></p>
      </template>

      <template v-slot:new-features>
        <p>None (for now!).</p>
      </template>
    </BlogEntryComponent>
  </article>
</template>

<script setup>
import { defineComponent } from "vue";
import BlogEntryComponent from "@/components/dating-and-dragons/BlogEntryComponent.vue";

defineComponent({
  name: "BlogEntryComponent",
  components: {
    BlogEntryComponent,
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/styles.scss";
</style>
