<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <article>
    <BlogEntryComponent
      postTitle="LVL 3 - Choose your companion!"
      date="October 14, 2024"
      author="Victor 'Kirlyan' Bello"
      authorTitle="Scroll Keeper"
      imgfileName="vic_avatar.webp"
    >
      <template v-slot:content>
        <p>Greetings, adventurers!</p>

        <p>
          If you've been keeping an eye on our TikTok or Instagram, chances are
          that you have already met some of our characters. Any of them got your
          interest? You’re in luck then, because in this post we’ll talk more in
          depth about them!
        </p>

        <p>
          Our romanceable characters are a huge part of Dating and Dragons—A
          Love Quest. Showing our babies to the world is so exciting for us, and
          we can't wait to hear your thoughts. Join our Discord to chat about
          your favorites and who you're planning to woo first ;)
        </p>

        <p>
          Now, without further ado, we give you three of the town’s most
          eligible bachelors!
        </p>

        <v-row>
          <v-col cols="12">
            <h4 class="dnd-primary-color">Yào</h4>
          </v-col>
        </v-row>
        <v-row align="start" justify="center">
          <v-col cols="12">
            <img
              id="yao-img"
              alt="yào the bard"
              :src="require(`@/assets/characters/yao.webp`)"
              class="character-img pl-8 pb-2"
            />
            <p>
              When we introduced Yào on our social media, our artist Lyd asked:
              “Are you in love already?” Well, we can assure you something: Yào
              is probably interested in you! “Prejudice” is a word that’s just
              missing from Yào’s dictionary. They’re the most approachable of
              our characters, and you’ll always be gifted with their dazzling
              smile.
            </p>

            <p>
              This bard is pure energy and spectacle. Get ready for thrilling
              shows, wild adventures and steamy moments. Yào’s favorite hangout
              is the tavern. You’ll often find them there, mixing with other
              characters. After all, everyone enjoys their company, talented
              music and witty conversation. Yào is also a bit of a mischievous
              one. Don't be surprised if they challenge you to a naughty
              drinking game. Ever played “fuck, marry, kill” in an RPG? You will
              now!
            </p>

            <p>
              Be warned, though! Yáo thinks they’re a love expert. Tons of
              adventurers out there claim to have won their heart. Can you be
              the one to make a lasting impression? Roll that Charm check!
            </p>
          </v-col>
        </v-row>

        <v-row class="my-8" align="center" justify="center">
          <v-col cols="12">
            <h4 class="dnd-primary-color">Obi</h4>
          </v-col>
          <v-col cols="12">
            <img
              id="obi-img"
              alt="Obi the blacksmith"
              :src="require(`@/assets/characters/obi.webp`)"
              class="character-img pl-8 pb-2"
            />
            <p>
              Ah, Obi, the ultimate Daddy figure. A pillar of the community, his
              forge is always open to those seeking weapons, tools, or just some
              reliable counsel. He might look distant, but don’t be fooled.
              Those crossed bulky arms hide a heart of gold and the warmest hugs
              in the game!
            </p>

            <p>
              As a blacksmith, Obi is passionate about his craft. Need a strong
              pair of hands? He's your guy. You might even learn a thing or two
              about metalwork while you're courting him. When not hammering
              away, he’s probably exercising or patrolling the village to keep
              it safe and sound. Dating Obi means getting sweaty, but we don’t
              expect many complains if he decides to strip down and show off
              those muscles...
            </p>

            <p>
              Have you noticed that necklace peeking out from his pocket? We’ve
              already received some comments asking about it. Must be something
              truly precious to Obi if he keeps it that close, don’t you think?
            </p>
          </v-col>
        </v-row>

        <v-row class="my-8" align="center" justify="center">
          <v-col cols="12">
            <h4 class="dnd-primary-color mb-2">Lily</h4>
          </v-col>
          <v-col cols="12">
            <img
              id="lily-img"
              alt="Lily the herbalist"
              :src="require(`@/assets/characters/lily.webp`)"
              class="character-img pl-8 pb-2"
            />
            <p>
              Lily’s our secret weapon for all the cozy lovers out there. Just
              imagine enjoying a cuppa together on a rainy day, reading a book
              by the chimney or cuddling near her soft skin and fluffy hair.
              We’re already jealous!
            </p>

            <p>
              She's deeply connected to nature, so expect plenty of outdoor
              adventures, from forest bathing to moonlit rituals. But beware of
              her rage if anything threatens the forest and its living
              creatures! Do not mistake her kindness for weakness. Lily’s a
              powerful sorceress, and she’ll turn you into an ooze before you
              can finish chewing the wrong fruit.
            </p>

            <p>
              Many come to Lily for guidance, and she’s always got an ear for
              those in need. However, whispers talk about some sadness buried
              deep at Lily’s gaze. She’s been around forever, and no one really
              knows her story. Can you crack her shell and unlock her heart?
            </p>
          </v-col>
        </v-row>

        <v-row class="my-8" align="center" justify="center">
          <v-col cols="12">
            <h4 class="dnd-primary-color mb-2">Nox</h4>
          </v-col>
          <v-col cols="12">
            <img
              id="nox-img"
              alt="Nox the Criminal"
              :src="require(`@/assets/characters/nox.webp`)"
              class="character-img pl-8 pb-2"
            />
            <p>
              Pssst, Kirlyan (game's writer) talking. Not sure if I’m allowed to
              pick a favorite, buuuuut it might be Nox. He's basically my first
              roleplay character reincarnated. Total outcast, with tragic and
              traumatic past, now full of bitterness and resentment. You get it,
              all the classic edgy vibes.
            </p>

            <p>
              Nox is a walking red flag. He proudly thinks himself a lone wolf,
              only loyal and amiable to his most inner circle. To the rest of
              the world, he’s a complete brat, his words as sharp as his
              daggers. Weirdly, even though he’s well known for being the most
              powerful crime lord of the town, the villagers seem to tolerate
              him. After all, he calls this place home, and “looking out for my
              people” is his unofficial motto.
            </p>

            <p>
              Bear with me and indulge Nox a bit of his toxic behavior during
              your playthroughs. Who knows? Maybe you can show him a better
              way... or might you just join in and wreak havoc together?
            </p>
          </v-col>
        </v-row>

        <v-row class="my-8" align="center" justify="center">
          <v-col cols="12">
            <h4 class="dnd-primary-color mb-2">Kára</h4>
          </v-col>
          <v-col cols="12">
            <img
              id="kara-img"
              alt="Kara the Monster Hunter"
              :src="require(`@/assets/characters/kara.webp`)"
              class="character-img pl-8 pb-2"
            />
            <p>
              Now, if you’re the adventurous type and are looking for some
              action, then Kára is your go-to girl. Distinguished member of the
              Adventurers Guild, she earns her living as a monster hunter.
              Always top tier in the Guild’s ranking, she’s known to have quite
              a competitive spirit.
            </p>

            <p>
              Everyone at the town will share a few nice words about Kára. Got a
              giant rat infestation? She's your exterminator. Some bratty
              outsiders disrupting the peace at the tavern? She’ll quickly
              remind them how healthy is to keep the head attached to the neck
              while sharing a pint or two. The town should just give her a
              lifetime achievement award for being an awesome neighbor –or at
              least the loudest one!
            </p>

            <p>
              The only sure way to darken Kára’s mood is to ask her about her
              past. So, be a good pal and avoid the topic while you enjoy the
              ride with this adrenaline junkie. Who knows, this is a dangerous
              world and tomorrow might never come!
            </p>
          </v-col>
        </v-row>

        <v-row class="my-8" align="center" justify="center">
          <v-col cols="12">
            <h4 class="dnd-primary-color mb-2">Ravi</h4>
          </v-col>
          <v-col cols="12">
            <img
              id="ravi-img"
              alt="Ravi the scholar"
              :src="require(`@/assets/characters/ravi.webp`)"
              class="character-img pl-8 pb-2"
            />
            <p>
              Meet Ravi, our most prolific and enthusiastic sage! Ravi thrives
              at the boundary between magic and science, always pushing the
              limits of reality’s very fabric. Expect the unexpected if you
              decide to spend some quality time together – and probably a few
              explosions!
            </p>

            <p>
              Dating Ravi is like trying to catch a squirrel on a sugar rush,
              always a million projects going on. Those closest to him know,
              however, that Ravi enjoys gathering his friends as much as anyone.
              He might be the one inventing a PowerPoint party in our world. We
              have no idea when this guy sleeps, though!
            </p>

            <p>
              Curious about his ghostly arm? Yeah, there’s a story there, but
              it’s not ours to tell. Why don’t you ask Ravi over a nice glass of
              wine? No doubt he’ll give you a full dramatized version of the
              tale!
            </p>
          </v-col>
        </v-row>

        <p>
          That’s it for now! We hope you’ve enjoyed meeting our characters, and
          we’ll be running a poll in our Discord Server to learn which one is
          your favorite. Join and let us know!
        </p>

        <p>
          We’ll be back soon with more updates about the development process.
          Until then, may the dice always roll in your favor!
        </p>
      </template>

      <template v-slot:signature>
        <p><b>Twisted Twenty Studio</b></p>
      </template>

      <template v-slot:new-features>
        <p>None (yet!).</p>
      </template>
    </BlogEntryComponent>
  </article>
</template>

<script setup>
import { defineComponent } from "vue";
import BlogEntryComponent from "@/components/dating-and-dragons/BlogEntryComponent.vue";

defineComponent({
  name: "BlogEntryComponent",
  components: {
    BlogEntryComponent,
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/styles.scss";

p {
  text-align: justify;
}

.character-img {
  max-height: 360px;
  float: right;
}

@media (max-width: 520px) {
  .character-img {
    display: block;
    float: none;
    margin: 0 auto $margin-xs auto !important;
    padding: 0 !important;
  }
}

h4 {
  font-size: 18px;
}
</style>
