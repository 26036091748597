<template>
  <v-footer class="footer-container d-flex flex-column">
    <div>
      <b class="mb-2">Get connected with us on social media!</b>
      <SocialMediaIconsComponent />
      Contact us at
      <a
        class="contact-link t20-secondary-color"
        href="mailto:contact@twistedtwenty.com"
        >contact@twistedtwenty.com</a
      >!
    </div>

    <div class="px-4 py-2 text-center w-100">
      {{ new Date().getFullYear() }} —
      <b>&copy; Twisted Twenty (Studios)</b>
    </div>

    <div class="privacy-policy-text px-4 py-2 w-100">
      <a
        class="pointer underline-hover t20-secondary-color mr-2"
        target="_blank"
        href="/privacy-policy"
        >Privacy policy disclaimer</a
      >
      <a
        class="pointer underline-hover t20-secondary-color"
        target="_blank"
        href="/legal"
        >Legal disclaimer</a
      >
    </div>
  </v-footer>
</template>

<script setup lang="ts">
import { defineComponent, defineProps } from "vue";
import SocialMediaIconsComponent from "@/components/SocialMediaIconsComponent.vue";

defineComponent({
  name: "FooterComponent",
  components: {
    SocialMediaIconsComponent,
  },
});

defineProps({
  // Colors available: 'white' and 'yellow' for now
  color: {
    type: String,
    default: "white",
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/index.scss";

footer {
  color: $white;
  background-color: $black;
  padding: 20px;
  text-align: center;
}

.footer-container {
  border-top: 2px solid $white;
  z-index: 1001;
}

.contact-link {
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: #db636f;
  }
}

.icon {
  width: 24px;
}

.privacy-policy-text {
  font-size: 12px;
}
</style>
