import { reactive } from "vue";

// TODO: if this service is not used in the future, remove it
class ExternalUrlService {
  state = reactive({
    showModal: false,
    url: "",
  });

  get modalState() {
    return this.state.showModal;
  }
  set modalState(value: boolean) {
    this.state.showModal = value;
  }

  get url() {
    return this.state.url;
  }
  set url(value: string) {
    this.state.url = value;
  }

  openUrlModal(url: string) {
    this.url = url;
    this.modalState = true;
  }

  goToUrl() {
    window.open(this.url, "_blank");
    this.modalState = false;
  }
}

const externalUrlService = new ExternalUrlService();

export default externalUrlService;
