<template>
  <v-card class="monster-card" height="100%">
    <v-card-title class="mt-4">
      <h3>{{ monster.name }}</h3>
    </v-card-title>
    <v-card-subtitle
      ><p class="monster-subtitle dnd-tagline">
        {{ monster.type }}
      </p></v-card-subtitle
    >
    <v-img
      :src="monster.img"
      cover
      aspect-ratio="1"
      alt="Team member image"
      class="monster-img"
    ></v-img>
    <v-card-text class="monster-text">
      <div class="monster-stats pa-4 h-100">
        <v-container class="mb-2">
          <v-row align="center">
            <v-col cols="4" md="2" sm="2" lg="4" class="stat">
              <b>MUSCLES</b>
              <p>
                {{ monster.strength }} ({{ getSign(monster.strength)
                }}{{ calculateModifier(monster.strength) }})
              </p>
            </v-col>
            <v-col cols="4" md="2" sm="2" lg="4" class="stat">
              <b>DEXERITY</b>
              <p>
                {{ monster.dexterity }} ({{ getSign(monster.dexterity)
                }}{{ calculateModifier(monster.dexterity) }})
              </p>
            </v-col>
            <v-col cols="4" md="2" sm="2" lg="4" class="stat">
              <b class="text-no-wrap">TOUGHNESS</b>
              <p>
                {{ monster.constitution }} ({{ getSign(monster.constitution)
                }}{{ calculateModifier(monster.constitution) }})
              </p>
            </v-col>
            <v-col cols="4" md="2" sm="2" lg="4" class="stat">
              <b>BRAINS</b>
              <p>
                {{ monster.intelligence }} ({{ getSign(monster.intelligence)
                }}{{ calculateModifier(monster.intelligence) }})
              </p>
            </v-col>
            <v-col cols="4" md="2" sm="2" lg="4" class="stat">
              <b>COMMON SENSE</b>
              <p>
                {{ monster.wisdom }} ({{ getSign(monster.wisdom)
                }}{{ calculateModifier(monster.wisdom) }})
              </p>
            </v-col>
            <v-col cols="4" md="2" sm="2" lg="4" class="stat">
              <b>CHARM</b>
              <p>
                {{ monster.charisma }} ({{ getSign(monster.charisma)
                }}{{ calculateModifier(monster.charisma) }})
              </p>
            </v-col>
          </v-row>
        </v-container>

        <v-divider
          class="border-opacity-75 margin-xs-y dnd-primary-color mb-2"
          :thickness="4"
        ></v-divider>

        <v-container>
          <v-row
            align="center"
            v-for="ability in monster.specialAbilities"
            :key="ability.name"
          >
            <v-col cols="12" class="ability">
              <p>
                <b
                  ><i>{{ ability.name }}.</i></b
                >
                {{ ability.description }}
              </p>
            </v-col>
          </v-row>
        </v-container>

        <v-container class="actions">
          <v-row align="center">
            <v-col cols="12" class="stat">
              <h4 class="dnd-primary-color">ACTIONS</h4>
            </v-col>
          </v-row>
        </v-container>

        <v-divider
          class="border-opacity-75 mb-2 dnd-primary-color"
          :thickness="4"
        ></v-divider>

        <v-container>
          <v-row
            align="center"
            v-for="action in monster.actions"
            :key="action.name"
          >
            <v-col cols="12" class="ability">
              <p class="last-action-no-margin">
                <b
                  ><i>{{ action.name }}.</i></b
                >
                {{ action.description }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { defineProps, ref } from "vue";
import { Monster } from "@/models/monster";

defineProps<{
  monster: Monster;
}>();

const calculateModifier = ref((abilityScore: number) => {
  return Math.floor((abilityScore - 10) / 2);
});
const getSign = ref((abilityScore: number) => {
  return abilityScore >= 10 ? "+" : "";
});
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/styles.scss";

.monster-subtitle {
  font-size: 1.1em;
  color: $black;
  line-height: unset;
}

.ability {
  text-align: start;
  padding: 0;
}

.stat {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  padding: 0;

  b {
    color: $dnd-primary-color;
    margin-bottom: 0;
    font-size: 0.8em;
    overflow: hidden;
  }

  p {
    font-size: 0.9em;
    margin-bottom: 0;
  }
}

.actions {
  text-align: start;
  font-size: 1.1em;
}

.monster-card {
  background: url("../../assets/monster_background.webp");
  background-size: cover;
  box-shadow: 0px 0px 12px 4px $box-shadow-color;
  border-top: 4px solid $dnd-primary-color;
  border-bottom: 4px solid $dnd-primary-color;

  display: flex;
  flex-direction: column;

  .monster-img {
    flex: 0 0 auto;
  }

  .monster-text {
    flex-grow: 1;

    .monster-stats {
      border: 1px solid $dnd-primary-color;
    }
  }
}

.last-action-no-margin:last-child {
  margin-bottom: 0;
}
</style>
