<template>
  <div class="d-flex justify-center align-center flex-nowrap ga-1">
    <v-tooltip text="Coming soon!" location="bottom">
      <template v-slot:activator="{ props }">
        <svg-icon
          v-bind="props"
          type="mdi"
          :path="mdiSteam"
          :size="24"
          class="disabled-icon"
        >
        </svg-icon>
      </template>
    </v-tooltip>

    <v-tooltip text="Coming soon!" location="bottom">
      <template v-slot:activator="{ props }">
        <svg-icon
          v-bind="props"
          type="mdi"
          :path="mdiKickstarter"
          :size="24"
          class="disabled-icon"
        />
      </template>
    </v-tooltip>

    <svg-icon
      type="mdi"
      :path="mdiLinkBoxOutline"
      :size="24"
      @click="navigateTo('https://linktr.ee/twisted_twenty')"
    />
    <IconComponent
      hover-color="#ed9600"
      pointer
      icon="tiktok"
      :size="20"
      @click="navigateTo('https://www.tiktok.com/@dating.and.dragon')"
    />
    <svg-icon
      type="mdi"
      :path="mdiInstagram"
      :size="24"
      @click="navigateTo('https://www.instagram.com/dating.and.dragons')"
    />
    <svg-icon
      type="mdi"
      :path="mdiReddit"
      :size="24"
      @click="navigateTo('https://www.reddit.com/r/DatingAndDragons/')"
    />

    <IconComponent
      hover-color="#ed9600"
      pointer
      icon="discord"
      :size="24"
      @click="navigateTo('https://discord.gg/kEeEV3HZaW')"
    />
  </div>
</template>

<script setup lang="ts">
import { defineComponent } from "vue";
import { mdiLinkBoxOutline } from "@mdi/js";
import { mdiInstagram } from "@mdi/js";
import { mdiKickstarter } from "@mdi/js";
import { mdiReddit } from "@mdi/js";
import { mdiSteam } from "@mdi/js";

import SvgIcon from "@jamescoyle/vue-icon";
import IconComponent from "@/components/IconComponent.vue";

defineComponent({
  name: "SocialMediaIconsComponent",
  components: {
    SvgIcon,
    IconComponent,
  },
});

const navigateTo = (url: string) => {
  window.open(url, "_blank");
};
</script>

<style scoped lang="scss">
@import "@/styles/index.scss";

svg:hover {
  color: $t20-secondary-color;
  cursor: pointer;
}

.disabled-icon {
  color: $grey;
  &:hover {
    color: $grey;
  }

  cursor: default !important;
}
</style>
