<template>
  <v-container class="game-summary-container py-16" fluid>
    <v-row :justify="'center'" class="mb-4">
      <v-col cols="8" lg="6">
        <p
          aria-label="Dating and Dragons Game Description"
          class="game-description-text"
        >
          Dating and Dragons is a dating sim set in a fantasy world filled with
          adventure and magic. Choose your character and embark on a quest for
          love! Six unique romance interests, dozens of secret endings, and
          hundreds of choices await. Use your skills wisely, and may your dice
          always roll in your favour!
        </p>
      </v-col>
    </v-row>
    <v-row :justify="'center'" class="mb-2">
      <v-col
        cols="8"
        lg="6"
        class="frame-container px-8 py-4 px-md-16 py-md-8 h-100 w-100"
      >
        <p class="mb-0">
          The game is currently in development. We’re working very hard to make
          something we can be proud of. <br />We hope you’ll enjoy playing it as
          much as we enjoy creating it!
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss">
@import "@/styles/variables.scss";

.game-description-text {
  font-size: 1.15rem;
  color: $white;
  text-shadow: $black 0 0 8px;
}

.frame-container {
  background: url("../../assets/monster_background.webp");
  background-size: cover;
  box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
  border-top: 4px solid $dnd-primary-color;
  border-bottom: 4px solid $dnd-primary-color;
}

.game-summary-container {
  background-image: url("../../assets/game_summary_background.webp");
  background-size: 2000px 1000px;
  background-repeat: repeat;
}
</style>
