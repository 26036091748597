import type { Schema } from "../../amplify/data/resource";
import { generateClient } from "aws-amplify/data";

class NewsletterSubscriptionService {
  client;

  constructor() {
    this.client = generateClient<Schema>();
  }

  postNewsletterSubscription = async (mailToSubscribe: string) => {
    return this.client.models.NewsletterMail.create({
      email: mailToSubscribe.toLowerCase(),
      id: mailToSubscribe,
    });
  };
}

const newsletterSubscriptionService = new NewsletterSubscriptionService();

export default newsletterSubscriptionService;
